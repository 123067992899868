@use '../config/' as *;
.waf-squad {
    .waf-component {
        @extend %py-6;
        @extend %my-6;
    }
}
.waf-squad {
    .title {
        @extend %title-50;
        @extend %neutral-100;
        @extend %mb-0;
    }
    .head-wrap {
        @extend %flex-sb-c;
        @extend %mb-8;
        a {
            @extend %btn-default;
            @extend %gap-2;
            &:after {
                @include icon(arrow-small-right, 24);
            }
        }
    }
    .form-wrapper,
    .player-content-head,
    .player-bio,
    .meta-date,
    .pagination-wrap {
        display: none; //temp
    }
    .men {
        .player-thumbnail {
            border-color: var(--primary-50);
        }
    }
    .women {
        .player-thumbnail {
            border-color: var(--secondary-70);
        }
    }
    .player {
        &-thumbnail {
            width: 4.8rem;
            aspect-ratio: 1/1;
            border: 0.2rem solid;
            @extend %flex;
            @extend %circle-radius;
        }
        &-img {
            object-fit: contain;
            flex-shrink: 1;
            @extend %circle-radius;
        }
        &-name {
            @extend %body-mb;
        }
        &-item-list {
            @include grid(3);
            row-gap: var(--space-4);
        }
        &-item {
            @extend %p-2;
        }
        &-body {
            display: grid;
            @extend %gap-2;
        }
    }
    .meta {
        &-origin {
            flex-direction: row-reverse;
            @extend %flex-fe-c;
            @extend %gap-2;
            img {
                width: 1.6rem;
                aspect-ratio: 1/1;
                @extend %circle-radius;
            }
        }
    }
    .team {
        &-name {
            @extend %body-sb;
            @extend %neutral-70;
        }
    }
}
@include mq(col-tablet) {
    .waf-squad {
        .player {
            &-thumbnail {
                width: 6.4rem;
            }
            &-body {
                grid-auto-flow: column;
                grid-template-columns: 6.4rem auto;
            }
            &-info {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        .meta-origin {
            img {
                width: 2.4rem;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-squad {
        .title {
            font-size: 3.2rem;
        }
        .player {
            &-item {
                padding: var(--space-4) var(--space-6);
            }
            &-name {
                font-size: 2rem;
            }
        }
        .team-name {
            font-size: 2rem;
        }
    }
}