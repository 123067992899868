@use '../config/' as *;
.social {
    &-list-wrapper {
        max-width: var(--container-max-width);
        @extend %my-10;
        @extend %mx-auto;
        @include bgImg('cssimages/world-ranking/paris.png', center, cover);
        @extend %p-4;
        @extend %border-radius-m;
    }
    &-logo {
        height: 3.2rem;
        img {
            filter: invert(1);
            @extend %h-100;
        }
    }
    &-link-list {
        display: grid;
        @extend %gap-6;
        @extend %py-6;
        &:not(:first-child) {
            border-top: 0.1rem solid var(--neutral-5);
        }
    }
    &-list {
        flex-wrap: wrap;
        @extend %flex-c-c;
        @extend %gap-6;
        @extend %px-12;
    }
    &-text {
        @extend %font-0;
    }
    &-head {
        @extend %text-center;
        .txt {
            @extend %neutral-0;
            @extend %title-50;
        }
    }
}
.waf-history {
    &.waf-component{
        @extend %p-y-10-16;
        @extend %mb-0;
    }
    .waf-head {
        @extend %mb-6;
    }
    .waf-body {
        display: grid;
        @extend %gap-6;
    }
    .text {
        @extend %body-l;
        + .text{
            @extend %mt-6;
        }
    }
    .btn-link {
        @extend %gap-2;
        @extend %btn-fill;
        @extend %mt-6;
        @extend %mx-auto;
        .btn-text{
            @extend %text-center;
        }
        &::after{
            font-weight: 400;
            @include icon(arrow-right, 24);
        }
    }
    &.history-about{
        .waf-body{
            display: unset;
            gap: unset;
        }
        .layout-wrapper {
            display: grid;
        }
    }
}
@include mq(col-tablet){
    .waf-history{
        .btn-link{
            width: max-content;
        }
        &.history-about{
            .title{
                font-size: 3.2rem;
            }
        }
    }
}
@include mq(col-lg) {
    .social {
        &-list-wrapper {
            position: relative;
            display: flex;
            justify-content: space-around;
            margin-block: var(--space-22);
            &::after {
                content: '';
                height: 75%;
                width: 0.1rem;
                pointer-events: none;
                @include bg(neutral-5);
                @include position-combo(center);
            }
        }
        &-link-list {
            &:not(:first-child) {
                border-top: unset;
            }
        }
    }
    .waf-history {
        padding-block: var(--space-10);
        .waf-body {
            grid-template-columns: 41rem calc(100% - 43.4rem);
        }
        .btn-link {
            margin-inline: unset;
        }
        &.history-about{
            .waf-body{
                grid-template-columns: unset;
            }
            .layout-wrapper {
                gap: var(--space-6);
                grid-template-columns: 32% calc(68% - var(--space-6));
            }
        }
    }
}