@use './config/' as *;
@forward './main.scss';
@forward './swiper/swiper-bundle';
@forward './events/fourstar-shoswcase';
@forward './module/social-card';
@forward './squad/qualified-athletes';
@forward './events/events';
@forward './module/sports-details';
@forward './module/triathlon-live';
@forward './standings/olympic-history';
.waf-listing {
    &.waf-overlay-list {
        @extend %pb-10;
        .waf-body {
            margin-right: calc(-1*var(--container-white-space));
        }
        .article-list {
            grid-auto-flow: column;
            grid-auto-columns: calc(100% - var(--space-6));
            grid-template-columns: unset;
            overflow-x: auto;
        }
    }
    &.waf-horizontal-list {
        @extend %pb-10;
    }
}
.waf-broadcast {
    background-color: var(--neutral-0);
    .tab-section,
    .waf-head,
    .card-list {
        display: none; //temp
    }
    .streaming,
    .streaming-thumbnail {
        margin: 0;
    }
    .tab-container-item {
        @extend %p-y-0-10;
        &::before {
            @include bgImg("cssimages/world-ranking/paris.png", null, 100% 100%);
        }
    }
    .streaming-list {
        @extend %m-y-8-0;
    }
}
.waf-sports-details.waf-component {
    @extend %my-10;
    @extend %pb-10;
    .layout-wrapper {
        padding: 0;
    }
}
.waf-classes-explaination {
    .title {
        @extend %mb-4;
    }
    .head-text {
        @extend %mb-6;
    }
    .head-text,
    .item,
    .text {
        @extend %body-l;
    }
    .class-list {
        list-style-type: "-";
        @extend %mb-5;
    }
    .item {
        @extend %pl-1;
    }
    .defination-list {
        > li {
            @extend %mb-4;
        }
    }
}
.waf-article-gallery {
    &.waf-component {
        @extend %py-20;
    }
    .title {
        @extend %title-50;
        @extend %mb-4;
    }
    .article-gallery {
        @extend %mx-4-neg;
        &-list {
            display: grid;
            grid-template-rows: auto auto auto;
            grid-auto-flow: column;
            grid-gap: var(--space-4);
            @extend %x-auto;
            @extend %px-4;
        }
        &-item {
            width: 22.5rem;
            &:not(:last-child),
            &:last-child {
                margin-bottom: var(--space-0);
            }
        }
    }
    .img-box {
        aspect-ratio: 7/8;
        @extend %border-radius-m;
    }
    .article-more-item {
        @extend %relative;
        figcaption {
            background: linear-gradient(180deg, hsla(var(--hsl-pure-black-0)/0) 0%, hsla(var(--hsl-pure-black-0)/0.8) 76.04%), linear-gradient(0deg, hsla(var(--hsl-pure-black-0)/0.1), hsla(var(--hsl-pure-black-0)/0.1));
            @extend %neutral-0;
            @extend %border-radius-m;
            @extend %w-100;
            @extend %h-100;
            @extend %position-t-l;
            @extend %flex-c-c;
            @extend %title-40;
        }
    }
}
.footer-wrapper:before {
    background: var(--neutral-0);
}
.widget-layout-11 {
    &.waf-standings {
        @extend %p-y-5-5;
    }
    .layout-wrapper {
        background: transparent;
        padding: 0;
    }
    .meta-link {
        &::after {
            color: var(--neutral-100);
        }
        // a {
        //     text-decoration: underline;
        //     color: var(--neutral-100);
        // }
    }
}
.waf-evenstrip {
    .card {
        &-list {
            flex-wrap: wrap;
        }
        &-item {
            width: max-content;
            min-width: 26rem;
            height: unset;
            align-items: center;
        }
        &-body {
            flex-grow: 1;
        }
    }
    .swiper {
        &-button-prev,
        &-button-next,
        &-pagination {
            @extend %d-none;
        }
    }
}
@include mq(col-tablet) {
    .waf-listing {
        &.waf-horizontal-list {
            .article {
                &-list {
                    @include grid(2);
                }
                &-title {
                    -webkit-line-clamp: 4;
                }
            }
        }
        &.waf-overlay-list {
            .waf-body {
                margin-right: 0;
            }
            .article-list {
                grid-auto-flow: unset;
                grid-auto-columns: unset;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                overflow-x: unset;
            }
        }
    }
    .waf-article-gallery {
        .article-gallery {
            margin-inline: var(--space-8-neg);
            &-list {
                padding-inline: var(--space-8);
                grid-gap: var(--space-6);
            }
            &-item {
                width: 30.2rem;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-listing {
        &.waf-overlay-list {
            .article-list {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }
    }
    .waf-broadcast {
        .streaming-thumbnail {
            margin-top: calc(var(--space-22-neg) + var(--space-10-neg));
        }
        .tab-container-item {
            padding-block: var(--space-10);
        }
    }
    .waf-article-gallery {
        .title {
            margin-bottom: var(--space-10);
            font-size: 3.2rem;
        }
        .article-gallery {
            margin-inline: var(--space-0);
            &-list {
                padding-inline: var(--space-0);
                grid-auto-flow: row;
                @include grid(4, var(--space-6));
            }
            &-item {
                width: unset;
            }
        }
    }
    .widget-layout-11 {
        &.waf-standings {
            padding-block: var(--space-10);
            margin-bottom: 0;
        }
    }
    .waf-evenstrip {
        .card {
            &-list {
                align-items: stretch;
            }
        }
    }
}