@use '../config/' as *;
.widget-layout-11 {
    // &.waf-standings {
    //     @extend %p-y-5-5;
    // }
    .layout-wrapper {
        background: var(--neutral-5);
        padding-right: 0;
        @extend %p-4;
        @extend %border-radius-l;
    }
    .waf-head {
        @extend %mb-6;
    }
    .waf-body {
        padding-bottom: 0;
    }
    .title {
        @extend %neutral-100;
        @extend %title-40;
        @extend %mb-2;
    }
    @extend %ranking-table;
    .table {
        &-title {
            @extend %neutral-100;
            @extend %title-50;
            @extend %mb-4;
        }
        &-wrapper {
            padding-right: var(--space-4);
            padding-block: 0;
            @include card-count(1.01, var(--space-4));
            @extend %flex;
        }
        &-footer,
        &-head,
        &-body .card-thumbnail {
            display: none;
        }
        &-row {
            border-block: .1rem solid hsl(var(--hsl-neutral-20)/1);
            height: 5.73rem;
            &:first-child {
                border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
            }
            &:not(:first-child) {
                .card-logo {
                    margin-left: 0;
                }
            }
            .card {
                &-name {
                    max-width: 100%;
                }
            }
        }
        &-data{
            &.info{
                .text{
                    @include truncate-text(1);
                }
            }
        }
    }
    .head-meta,
    .event-type {
        flex-wrap: wrap;
        @extend %gap-4;
        @extend %flex-n-c;
    }
    .meta {
        display: inline-flex;
        align-items: center;
        @extend %relative;
        @extend %text-m;
        @extend %gap-2;
        @extend %pr-4;
        &::before {
            content: '';
            width: .4rem;
            aspect-ratio: 1/1;
            position: absolute;
            right: 0;
            @extend %circle-radius;
            @extend %bg-neutral-100;
        }
        &:last-of-type{
            &::before{
                content: unset;
            }
        }
        &-country {
            @extend %gap-2;
            img {
                flex-shrink: 0;
                width: 2.4rem;
                height: 2.4rem;
                aspect-ratio: 1/1;
                @extend %flex;
            }
        }
        &-link {
            padding-right: 0;
            &::before{
                content: unset;
            }
            &::after {
                @include icon(arrow-top-right-on-square, 24);
                @extend %primary-50;
            }
            // a {
            //     @extend %primary-50;
            // }
        }
        &-link,
        &-country {
            font-weight: 700;
        }
        &-event-date {
            .type {
                font-weight: 700;
            }
        }
    }
}
@include mq(col-tablet) {
    .widget-layout-11 {
        .layout-wrapper {
            padding: var(--space-10);
        }
        .waf-head {
            margin-bottom: var(--space-8);
        }
        .table {
            &-wrapper {
                padding-right: unset;
            }
            &-item {
                width: calc(50% - 1*var(--space-6)/2);
            }
            &-body{
                .card-thumbnail{
                    display: block;
                }
            }
        }
        .event-type {
            width: 100%;
        }
    }
}
@include mq(col-lg) {
    .widget-layout-11 {
        &.waf-standings {
            margin-bottom: var(--space-14);
        }
        .head-wrap {
            padding-bottom: 0;
        }
        .meta {
            font-size: 2rem;
            &.meta-link {
                font-size: 1.4rem;
            }
        }
        .title {
            font-size: 4rem;
            margin-bottom: var(--space-4);
        }
    }
}

@include mq(col-desktop){
    .widget-layout-11{
        .table{
            &-item {
                width: calc(33.33% - 1*var(--space-6)/2);
            }
        }
    }
}