@use '../config/' as *;
.waf-evenstrip {
    &.waf-component {
        @extend %py-10;
        @extend %mb-0;
    }
    .waf-head,
    .card-thumbnail,
    .card-source,
    .label {
        display: none;
    }
    .card {
        &-list {
            flex-direction: column;
            @extend %flex-c-c;
            @extend %gap-4;
        }
        &-item {
            flex-direction: column-reverse;
            backdrop-filter: blur(0.4rem);
            background: hsla(var(--hsl-neutral-5)/.3);
            @extend %flex;
            @extend %p-4;
            @extend %text-center;
            @extend %border-radius-s;
            &:first-child {
                margin-top: 7.6rem;
                .card-logo {
                    width: 17rem;
                    @include position-combo(x-center, -7.6rem);
                    @extend %d-block;
                }
            }
        }
        &-status {
            @extend %flex-column;
            @extend %gap-4;
        }
        &-title {
            @extend %title-50;
        }
        &-logo {
            max-height: 6rem;
            aspect-ratio: 25/9;
            @extend %d-none;
            .text {
                @extend %font-0;
            }
        }
    }
    .counter {
        @extend %flex-sb-c;
        @extend %gap-2;
    }
    .number {
        @extend %text-mb;
    }
    .text {
        @extend %text-s;
    }
    .date {
        @extend %text-lb;
        em {
            font-style: normal;
            @extend %text-s;
        }
    }
}
@include mq(col-lg) {
    .waf-evenstrip {
        .card {
            &-list {
                flex-direction: row;
            }
            &-item {
                &:first-child {
                    margin-top: 0;
                    margin-left: 27rem;
                    .card-logo {
                        top: unset;
                        margin-inline: unset;
                        inset-inline: unset;
                        @include position-combo(y-center, null, -27rem);
                    }
                }
            }
        }
    }
}